<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card :loading="loading" :disabled="loading" loader-height="2">
          <v-card-text class="pb-0">
            <div class="d-flex align-center">
              <!-- <v-btn
                rounded
                color="primary"
                elevation="0"
                small
                class="mr-2"
                @click="$nova.gotoLink({ path: 'filelist/config' })"
                ><v-icon class="mr-1">mdi-plus</v-icon>Add</v-btn
              > -->
              <lb-string
                label="Search"
                outlined
                hidedetails
                v-model="seachList"
              ></lb-string>
              <v-spacer></v-spacer>
              <v-chip label small class="border-left-error" outlined
                >Disabled</v-chip
              >
              <v-btn icon small class="ml-2" @click="refreshData()"
                ><v-icon>mdi-refresh</v-icon></v-btn
              >
            </div>
          </v-card-text>
          <v-card-text>
            <lb-datatablenew :headers="headers" :items="searchResult" :enableslot="['action','status']" :loading="loading">
              <template v-slot:status="{ item }">
                 <v-chip x-small label text color="success" v-if="item.status"
                        >Active</v-chip
                      >
                      <v-chip x-small label text color="error" v-else
                        >Inactive</v-chip
                      >
              </template>
                <template v-slot:action="{ item }">
                  <div class="d-flex justify-center">
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="shadow-off"
                              small
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="ViewConfig(item)"
                            >
                              <v-icon color="">mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>View Config</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                              @click="
                                $nova.gotoLink({ path: 'filelist/config/' + item._id })
                              "
                            >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Config</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteConfig(item._id)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete Config</span>
                        </v-tooltip>
                        <v-tooltip
                          bottom
                          content-class="tooltip-bottom"
                          v-if="item.status"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              color="success"
                              v-bind="attrs"
                              v-on="on"
                              @click="disabledConfig(item._id)"
                            >
                              <v-icon>mdi-check-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Enable</span>
                        </v-tooltip>

                        <v-tooltip bottom content-class="tooltip-bottom" v-else>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              color="error"
                              v-bind="attrs"
                              v-on="on"
                              @click="enabledConfig(item._id)"
                            >
                              <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Disable</span>
                        </v-tooltip>
                  </div>
                </template>
          </lb-datatablenew>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <v-dialog v-model="configViewDialog" max-width="1000" persistent>
      <v-card class="" :loader-height="2">
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text">File Config View</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="configViewDialog = false" class="ml-2 white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text>
          <div class="subtitle-2 mb-2">Configuration Summary:</div>
          <div class="font-weight-black mb-4">Basic Details</div>
          <div class="mb-4">
            <div class="font-weight-bold">
              File Name :
              <span class="font-weight-regular">{{
              fileConfigView.file
              }}</span>
            </div>
            <div class="font-weight-bold">
              Ignore Rows :
              <span class="font-weight-regular">{{
                fileConfigView.ignorerows
              }}</span>
            </div>
            <div class="font-weight-bold">
              Ignore Column :
              <span class="font-weight-regular">
                {{ fileConfigView.ignorecolumns }}</span
              >
            </div>
          </div>
          <div class="font-weight-black mb-4">Column Maping</div>
          <div class="d-flex">
            <div>
              <div
                v-for="(fileCol, i) in fileConfigView.columnmap"
                :key="i"
                class="my-1"
              >
                <div class="d-flex align-center">
                  <v-chip
                    label
                    class="d-block flex-grow-1"
                    outlined
                    color="primary"
                  >
                    {{ fileCol[0] }}
                  </v-chip>
                  <div style="width: 50px">
                    <v-divider></v-divider>
                  </div>
                  <div
                    :class="`drag-droppable py-1 px-2 blue-grey lighten-5`"
                    style="min-width: 200px; border: 2px dashed #ccc"
                    @drop="onDrop($event, fileCol.name, i)"
                    @dragover.prevent
                    @dragenter.prevent
                  >
                    <div class="d-flex justify-center">
                      <span v-html="fileCol[1] || '&nbsp;'"></span>
                      <v-spacer></v-spacer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-2 mt-2">
            <div class="font-weight-black mb-4">Removed Columns</div>
            <v-chip
              v-for="(a, b) in (fileConfigView || []).ignoredcolumns"
              :key="b"
              class="ma-1"
              label
              text-color="black"
              color="blue-grey lighten-5"
            >
              {{ a }}
            </v-chip>
          </div>

          <div class="mb-2 mt-2">
            <div class="font-weight-black mb-4">Creat Columns</div>
            <v-chip
              v-for="(colum, i) in fileConfigView.unusedcolumns"
              :key="'C' + i"
              class="ma-1"
              label
              text-color="black"
              color="blue-grey lighten-5"
            >
              {{ colum }}
            </v-chip>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      seachList: '',
      fileItems: [],
      totalFile: [],
      loading: true,
      tab: null,
      configViewDialog: false,
      fileConfigView: {},
      formatedData:[],
      headers: [{
					value: "indexno",
					text: "#",
					sortable: false,
					filterable: false,
				},
				{
					value: "file",
					text: "File Name",
					sortable: true,
					filterable: true,
					lock: true,
				},
				{
					value: "ignorerows",
					text: "Ignore Rows",
					sortable: true,
					// filterable: true,
					lock: true,
				},
				{
					value: "ignorecolumns",
					text: "Ignore Columns",
					sortable: true,
					// filterable: true,
					lock: true,
				},
				{
					value: "status",
					text: "Status",
					// sortable: true,
					// filterable: true,
					lock: true,
				},
				{
					value: "action",
					text: "Action",
					sortable: false,
					filterable: false,
				},
			],
    };
  },
  created() {
    this.refreshData();
  },
  props: {
    filelist: {
      type: Array,
      required: false,
      default: ()=>[]
    }
  },
  methods: {
    refreshData() {
      this.loading = true;
      let ndt = {};
      if(this.filelist.length > 0) ndt = {filter: {filelist: this.filelist}};
      this.axios
        .post("/v2/conserve/fileconfig/getformdata", ndt)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.totalFile = dt.data.data;
            return this.axios.post("/v2/conserve/fileconfig/get", ndt);
          } else throw new Error(dt.data.message);
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.fileItems = dt.data.data;
            this.formateData();
            //   console.log(this.fileItems, "fileItems");
            //   console.log(this.totalFile, "fileItems");
            //   let fileDetails = this.totalFile.filter(({ _id: id1 }) =>
            //     this.fileItems.some(({ file: id2 }) => id1 === id2)
            //   );
            //   console.log(fileDetails, "fileDetailsfileDetails");
          } else throw new Error(dt.data.message);
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formateData() {
			let ObjectTemp = {};
			let ArrayTemp = [];
			this.fileItems.forEach((i, k) => {  
				ObjectTemp = {
					_id: i._id,
					indexno: k + 1,
					file: this.fileName(i.file),
					ignorerows: i.ignorerows,
					ignorecolumns: i.ignorecolumns,
					status:i.status,
          columnmap:i.columnmap,
          blankcolumnheaders:i.blankcolumnheaders,
          ignoredcolumns:i.ignoredcolumns,
          keepduplicatecolumns:i.keepduplicatecolumns,
          removeblankrows:i.removeblankrows,
          unusedcolumns:i.unusedcolumns,

				}
				ArrayTemp.push(ObjectTemp);
			});
			this.formatedData = ArrayTemp;      
		},
    ViewConfig(item) {
      this.fileConfigView = item;
      this.configViewDialog = true;
    },
    fileName(id) {
      let filedetils = this.totalFile.find((x) => x._id === id);
      return (filedetils || {}).name;
    },
    deleteConfig(id) {
      this.loading = true;
      this.axios
        .post("/v2/conserve/fileconfig/delete/" + id)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "Deleted successfully");
            this.refreshData();
          } else throw new Error(dt.data.message || "Error Delete File Config");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    enabledConfig(id) {
      this.loading = true;
      this.axios
        .post("/v2/conserve/fileconfig/enable/" + id)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "Enabled successfully");
            this.refreshData();
          } else throw new Error(dt.data.message || "Error Enable File Config");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    disabledConfig(id) {
      console.log(id);
      this.loading = true;
      this.axios
        .post("/v2/conserve/fileconfig/disable/" + id)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "Disabled successfully");
            this.refreshData();
          } else
            throw new Error(dt.data.message || "Error Disable File Config");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    searchResult() {
      console.log(this.formatedData,"this.formatedDatathis.formatedData");
      
      let tempList = this.formatedData;
      if (this.seachList != "" && this.seachList) {
        tempList = tempList.filter((item) => {
          console.log(item,this.seachList);
          return item.file.toUpperCase().includes(this.seachList.toUpperCase());
        });
      }
      return tempList;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-data-table {
  & td,
  & th {
    border: 1px solid #bbb !important;
  }
}
.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}
.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}
</style>

